body:not(.dark-mode) {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
}
.container:not(.dark-mode) {
  max-width: 100%;
  margin: 0 auto;
}

.product-card {
  position: relative;
  max-width: 380px;
  padding-top: 12px;
  padding-bottom: 43px;
  transition: all 0.0s;
  border: 1px solid #e7e7e7;
  background: #fff;
}

.product-card .product-buttons {
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, .11);
}

.product-card .product-button > a:hover {
  background-color: #ac32e4;
  color: #fff;
}

.product-card:hover {
  border-color: transparent;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, .09);
}
.product-card .product-title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}
.product-card .product-title > a {
  transition: color 0.3s;
  color: #343b43;
  text-decoration: none;
}
.product-card .product-title > a:hover {
  color: #2980b9;
}

.dark-mode .product-card {
  border-color: #333;
  background: #2c2c2c;
}

.dark-mode .product-card .product-buttons {
  background-color: #2c2c2c;
  box-shadow: 0 12px 20px 1px rgba(255, 255, 255, 0.1);
}

.dark-mode .product-card .product-button > a:hover {
  background-color: #68217a;
  color: #fff;
}

.dark-mode .product-card:hover {
  box-shadow: 0 12px 20px 1px rgba(255, 255, 255, 0.1);
}

.dark-mode .product-card .product-title {
  color: #fff; /* Light text color in dark mode */
}

.dark-mode .product-card .product-title > a {
  color: #fff; /* Lighter color for links in dark mode */
}

.dark-mode .product-card .product-title > a:hover {
  color: #64b5f6; /* Hover color for links in dark mode */
}

.product-category-card {
  display: block;
  max-width: 400px;
  text-align: center;
  text-decoration: none !important;
}
.product-category-card .product-category-card-thumb {
  display: table;
  width: 100%;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, .09);
}
.product-category-card .product-category-card-body {
  padding: 20px;
  padding-bottom: 28px;
}
.product-category-card .main-img, .product-category-card .thumblist {
  display: table-cell;
  padding: 15px;
  vertical-align: middle;
}
.product-category-card .main-img > img, .product-category-card .thumblist > img {
  display: block;
  width: 100%;
}
.product-category-card .main-img {
  width: 65%;
  padding-right: 10px;
}
.product-category-card .thumblist {
  width: 35%;
  padding-left: 10px;
}
.product-category-card .thumblist > img:first-child {
  margin-bottom: 6px;
}
.product-category-card .product-category-card-meta {
  display: block;
  padding-bottom: 9px;
  color: rgba(140, 140, 140, .75);
  font-size: 11px;
  font-weight: 600;
}
.product-category-card .product-category-card-title {
  margin-bottom: 0;
  transition: color 0.3s;
  color: #343b43;
  font-size: 18px;
}
.product-category-card:hover .product-category-card-title {
  color: #ac32e4;
}
.product-meta {
  padding-bottom: 10px;
}
.product-meta > a, .product-meta > i {
  display: inline-block;
  margin-right: 5px;
  color: rgba(140, 140, 140, .75);
  vertical-align: middle;
}
.product-meta > i {
  margin-top: 2px;
}
.product-meta > a {
  transition: color 0.25s;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}
.product-meta > a:hover {
  color: #8c8c8c;
}
.cart-item {
  position: relative;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #fff;
}
.cart-item .cart-item-label {
  display: block;
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.cart-item .cart-item-product {
  display: table;
  width: 420px;
  text-decoration: none;
}
.cart-item .cart-item-product-thumb, .cart-item .cart-item-product-info {
  display: table-cell;
  vertical-align: top;
}

.cart-item .cart-item-product-info {
  padding-top: 5px;
  padding-left: 15px;
}
.cart-item .cart-item-product-info > span {
  display: block;
  margin-bottom: 2px;
  color: #404040;
  font-size: 12px;
}
.cart-item .cart-item-product-title {
  margin-bottom: 8px;
  transition: color, 0.3s;
  color: #343b43;
  font-size: 16px;
  font-weight: bold;
}
.cart-item .cart-item-product:hover .cart-item-product-title {
  color: #ac32e4;
}
.cart-item .count-input {
  display: inline-block;
  width: 85px;
}
.cart-item .remove-item {
  right: -10px !important;
}
@media (max-width: 991px) {
  .cart-item {
    padding-right: 30px;
  }
  .cart-item .cart-item-product {
    width: auto;
  }
}
  .cart-item .cart-item-product {
    display: block;
    width: 100%;
    text-align: center;
  }
  .cart-item .cart-item-product-thumb, .cart-item .cart-item-product-info {
    display: block;
  }
  .cart-item .cart-item-product-thumb {
    margin: 0 auto 10px;
  }
  .cart-item .cart-item-product-info {
    padding-left: 0;
  }
  .cart-item .cart-item-label {
    margin-bottom: 8px;
  }

.card-wrapper {
  margin: 30px -15px;
}
@media (max-width: 576px) {
  .card-wrapper .jp-card-container {
    width: 260px !important;
  }
  .card-wrapper .jp-card {
    min-width: 250px !important;
  }
}
.filter-buttons {
  width: 100%;
  display: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.filter-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3px;
  padding: 8px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-button:hover {
  background-color: #2980b9;
}

.cart-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cart-popup-content {
  background-color: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  z-index: 1001;
}

.close-popup {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}

.cart-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clear-cart-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3px;
  padding: 8px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3px;
  padding: 8px;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-button:hover {
  background-color: #27ae60;
}

.clear-cart-button:hover {
  background-color: #c0392b;
}

.product-card button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-card button:hover {
  background-color: #2980b9;
}

.filter-buttons select {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 8px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-buttons select:hover {
  background-color: #2980b9;
}

.filter-buttons #subcategoryDropdown {
  margin-top: 10px;
  margin-left: 5px;
  padding: 8px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-buttons #subcategoryDropdown:hover {
  background-color: #2980b9;
}

body.dark-mode {
  background-color: #1a1a1a;
  color: #fff;
  width: 100%;
}

.container.dark-mode {
  background-color: #1a1a1a;
  color: #fff;
  max-width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.row:not(.dark-mode) {
  max-width: 1300px;
  margin: 0 auto;
}

.dark-mode-toggle {
  display: flex;
  align-items: center;
}

.dark-mode-toggle label {
  margin-right: 10px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.iphone-switch {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 8px;
  cursor: pointer;
}

.iphone-switch input {
  display: none;
}

.iphone-switch-label {
  position: absolute;
  top: -35%;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #3498db;
  border-radius: 12px;
  transition: transform 0.3s;
  cursor: pointer;
}

.iphone-switch input:checked + .iphone-switch-label {
  transform: translateX(100%);
}