/* CartPopup.css */

.cart-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-popup-content {
  background: #fff;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-radius: 8px;
}

.cart-popup-content {
  max-height: 600px; /* Pas dit aan op basis van je behoeften */
  overflow-y: auto;
}

.total-prices {
  position: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-radius: 8px;
  width: 100%;
}

h2 {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
}