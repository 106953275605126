.product-card.dark-mode {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .cart-popup-content {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .total-prices {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .cart-item {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .cart-item-price {
  background-color: #1a1a1a;
  color: #fff;
}